import React, { useEffect,useContext,useState } from "react";



import '../css/styles.css'
import '../css/vendor.css'

export default function About(match) {


    useEffect(()  => {
        
    },[]);



    return ( <div class="row">
    <p><strong>GANKO MEDIA SAGL</strong> es titular del sitio web  {window.location.hostname
}</p>
<p> </p>
<ol>
<li><strong> DATOS IDENTIFICATIVOS</strong>: En cumplimiento del deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de Información y del Comercio Electrónico, se reseñan los siguientes datos: La empresa titular de esta website es <strong>GANKO MEDIA SAGL</strong> con domicilio social en Via Cantonale, 4 – 6928 Manno, CH . Correo electrónico de contacto: legal@worldofwords.online</li>
</ol>
<p> </p>
<ol start="2">
<li><strong> USUARIOS</strong>: El acceso y/o uso de los portales de <strong>GANKO MEDIA SAGL</strong> atribuye la condición de usuario y que acepta, desde dicho acceso y/o uso, las Condiciones generales de uso aquí reflejadas.</li>
</ol>
<p> </p>
<ol start="3">
<li><strong> PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>: <strong>GANKO MEDIA SAGL</strong> por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de sus páginas webs, así como de los elementos contenidos en las mismas: a título meramente enunciativo, imágenes, sonidos, audio, vídeo, software, gráficos, fotografías o textos; marcas y logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc...</li>
</ol>
<p> </p>
<p>Todos los derechos reservados. En virtud de lo dispuesto en la vigente Ley de Propiedad Intelectual quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida la modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización expresa y por escrito de <strong>GANKO MEDIA SAGL</strong>. El Usuario se compromete a respetar los derechos de propiedad Intelectual titularidad de <strong>GANKO MEDIA SAGL</strong>. Podrá visualizar los elementos de las diferentes websites e incluso imprimirlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre que sea única y exclusivamente para su uso personal y privado. El Usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de <strong>GANKO MEDIA SAGL</strong>.</p>
<p> </p>
<p>La utilización no autorizada de la información contenida en esta website, su reventa, así como la lesión de los derechos de propiedad intelectual o industrial de <strong>GANKO MEDIA SAGL</strong> dará lugar a las responsabilidades legalmente establecidas.</p>
<p> </p>
<ol start="4">
<li><strong> EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong>: <strong>GANKO MEDIA SAGL</strong>, que pone todos los esfuerzos precisos para que los contenidos incluidos en esta página web sean fiables, no se hace responsable en ningún caso de los daños y perjuicios de cualquier naturaleza que se pudieran ocasionar al usuario derivados, a título enunciativo, de errores u omisiones en los contenidos, totalidad, precisión, oportunidad, falta de disponibilidad del portal o la transmisión de virus, a pesar de haber adoptado todas las medidas tecnológicas posibles para evitarlo.</li>
</ol>
<p> </p>
<p>Tanto el acceso a la web como el uso inconsentido que pueda efectuarse de la información contenida en la misma es de exclusiva responsabilidad de quien lo realiza y <strong>GANKO MEDIA SAGL</strong> no responderá de ninguna consecuencia, daño o perjuicio que pudiera derivarse de dicho acceso o uso.</p>
<p> </p>
<ol start="5">
<li><strong> MODIFICACIONES</strong>: <strong>GANKO MEDIA SAGL</strong> se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en sus websites, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de las mismas como la forma en que estos aparezcan presentados o localizados en sus websites.</li>
</ol>
<p> </p>
<ol start="6">
<li><strong> ENLACES</strong>: En caso de que en las websites se dispusiesen enlaces o hipervínculos hacia otros sitios de Internet, <strong>GANKO MEDIA SAGL</strong> no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso <strong>GANKO MEDIA SAGL</strong> asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</li>
</ol>
<p> </p>
<ol start="7">
<li><strong> ENLACES CON DESTINO A ESTA WEBSITE</strong>: Todo enlace de terceros a esta website debe serlo a su página principal, quedando expresamente prohibidos los “links profundos”, el “framing” y cualquier otro aprovechamiento de los contenidos de la web a favor de terceros no autorizado.</li>
</ol>
<p> </p>
<ol start="8">
<li><strong> GENERALIDADES: GANKO MEDIA SAGL</strong> perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de sus websites ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</li>
</ol>
<p> </p>
<p> </p>
<ol start="9">
<li><strong> MODIFICACIONES DE LAS PRESENTES CONDICIONES Y DURACIÓN</strong>: <strong>GANKO MEDIA SAGL</strong> podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones estará en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</li>
</ol>
<p> </p>
<ol start="10">
<li><strong> LEGISLACIÓN APLICABLE Y JURISDICCIÓN</strong>: La relación entre <strong>GANKO MEDIA SAGL</strong> y el Usuario se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y Tribunales de la ciudad de Lugano - CH.</li>
</ol>
    </div>)

}