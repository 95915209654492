const config = require("../../config.json"); 

export default {
    submitLead,
    postbackTaboola,
}

const getDomainWithoutSubdomain = url => {
    const urlParts = new URL(url).hostname.split('.')
  
    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join('.')
  }
  

async function submitLead(param){

    const domain = window.location.hostname;
    param.servizio = getDomainWithoutSubdomain(window.location)
    param.sono = "uomo"
    param.cerco = "donna"
    param.ua =  navigator.userAgent
    param.fluxId =  param.fluxId ?  param.fluxId : config[domain].default_fluxid
    param.country = config[domain].default_language

    if(!param.clickid || param.clickid == "" || param.clickid == undefined){
        param.clickid = await recuperaClickid(param)
    }

    if(!param.ip || param.ip == "" || param.ip == undefined){
        param.ip = await recuperaIp()
    }

    const url = "https://api.trkmyclick.com/users/offer_register"

    return await fetch(url,{ 
        method: 'post', 
        headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
        }) 
        .then(response => response.json())
        .then(res => {
			if(res.result){
               console.log("°°° SUCCESS °°°")
                return {success:true,red_url:res.red_url}
            }else{
                if(res.userMail){
                    return {success:false,red_url:res.userMail}
                }
                console.log("°°°° ERROR °°°°",res.error)
                logToBackend(res.error)
                return false
            }
		})
        .catch(err =>{
            logToBackend(err)
            console.log("Errore submitLead - TEKKA",err)
        }
    )

}




async function recuperaClickid(param){

    const url = "https://uniallel-conary.icu/dcde08b5-3710-4bc8-9d3f-21dd4ce2aacc?trafficSource="+param.source+"&type="+param.campid+"&page="+window.location.pathname+"&servizio="+param.servizio
    return await fetch(url)
    .then(response => response.json())
    .then(res => {
      return res.clickid
    }) 
}



async function recuperaIp(){

    const url = "https://api.trkmyclick.com/get/ip"
    return await fetch(url)
    .then(response => response.json())
    .then(res => {
      return res.ip
    }) 
}


async function logToBackend(messaggio){
    const oggetto = {
      subject:"Errore OFFER" + window.location.pathname,
      messaggio:messaggio
    }
    return await fetch("https://api.trkmyclick.com/log/client",{ 
      method: 'post', 
      headers: {
        'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
      },
      body: JSON.stringify(oggetto)
    }).then(()=>{return true})
    .catch((err) => {
      console.log("Problemi con logToBackend",err.message)
    })
  }



  async function postbackTaboola(){

      const ext_id = getUrlParameter("ext_id")
   
      var url = "https://trc.taboola.com/actions-handler/log/3/s2s-action?click-id="+ext_id+"&name=click_landing&partner-name=1479412&revenue=0&currency=EUR"

      return await fetch(url)
        .then(res =>  {
            return true
        }).catch(err=>{
            console.log("\x1b[33m%s\x1b[0m","ERRORE PIXEL",err)
            return false
            
        })

  }



  function getUrlParameter(parameter){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var param = urlParams.get(parameter) ? urlParams.get(parameter):null
    return param
  }