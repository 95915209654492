import React, { useEffect,useContext,useState } from "react";
import { useNavigate  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import Context from '../../_helpers/context';
import functions from '../../_helpers/functions'

import './Lp4.scoped.css'
const lang = require('../../_helpers/lang/lang.json')

export default function Lp4() {

    const context = useContext(Context)
  const locale = context.lang
  const landing = "lp4"

    


  return ( <div>
        <div className="mobile_center text-center">
            <img  className="img_avviso" src={require("./img/img1.png").default}></img>
            <div className="titolo_disclaimer">
                <p>Le donne registrate sono alla ricerca di incontri <br/><strong>Accetta per proseguire:</strong></p>
            </div>
            <div className="regole">
                <h3>1) EMAIL VALIDA (PER INCONTRI)</h3>
                <p>Ti verrà chiesto di inserire una email valida e di confermarla. E' obbligatorio che tutti gli utenti nel sito abbiano una <strong>email confermata.</strong></p>

                <h3>2) DISCREZIONE</h3>
                <p>Alcune donne potrebbero essere della tua zona o della tua città, è importante che tu <strong>mantenga segreta</strong> la loro presenza sul sito.</p>

                <Button onClick={() => { functions.postbackTaboola().then(()=>{  window.location.replace(context.voluum_domain) }) }} className="btn-grad">ACCETTO</Button>
            </div>
        </div>
        
  
  
    </div>);


}

