import React, { useEffect,useContext,useState } from "react";




export default function Blog(user) {


    useEffect(()  => {
        
    },[]);

 

    return (<div>
     <div className="comments-wrap">

<div id="comments" className="row">
    <div className="column">

        <h3>3 Comments</h3>


        <ol className="commentlist">

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-08.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Sara M.</div>

                        <div className="comment__meta">
                            <div className="comment__time">Jan 05, 2022</div>
                            <div className="comment__reply">
                              
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Evidentemente la maggior parte delle donne in questo sito è pronta ad uscire dopo solo pochi messaggi. Gli uomini che mi hanno contattato dopo alcuni messaggi mi hanno subito chiesto di incontrarci, dicendo che con le altre funziona così.</p>
                    </div>

                </div>

            </li> 

            <li className="thread-alt depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-01.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Mario M.</div>

                        <div className="comment__meta">
                            <div className="comment__time">Mar 05, 2022</div>
                            <div className="comment__reply">
                                
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Io sono iscritto da un anno ormai e mi diverto ancora. =P</p>
                    </div>

                </div>


            </li> 

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-04.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Lorenzo B.</div>

                        <div className="comment__meta">
                            <div className="comment__time">Sept 03, 2022</div>
                            <div className="comment__reply">
                               
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>E' utile per chi non sa avvicinarsi alle donne di persona</p>
                    </div>

                </div>

            </li> 

        </ol>
       

    </div> 
</div> 




</div> 
    </div>)

}




