import React, { useContext } from "react";
import { Routes, Route } from 'react-router-dom';

import Menu_ES from "./components/ES/menu";
import Content_ES from "./components/ES/content";
import Comments_ES from "./components/ES/comments";
import Footer_ES from "./components/ES/footer";
import AboutUs_ES from "./components/ES/about";

import Menu_IT from "./components/IT/menu";
import Content_IT from "./components/IT/content";
import Comments_IT from "./components/IT/comments";
import Footer_IT from "./components/IT/footer";
import AboutUs_IT from "./components/IT/about";

import Menu_De from "./components/DE/menu";
import Content_DE from "./components/DE/content";
import Comments_DE from "./components/DE/comments";
import Footer_DE from "./components/DE/footer";
import AboutUs_DE from "./components/DE/about";

import Category from "./components/category";
import Home from "./components/home";
import Terms from "./components/terms";
import Privacy from "./components/privacy";

import Context from '../../_helpers/context';

import './css/styles.css';
import './css/vendor.css';

const Blog = () => {
  const context = useContext(Context);
  const locale = context.lang;

  let Menu, Content, Comments, Footer, AboutUs;

  switch (locale) {
    case 'es':
      Menu = Menu_ES;
      Content = Content_ES;
      Comments = Comments_ES;
      Footer = Footer_ES;
      AboutUs = AboutUs_ES;
      break;
    case 'it':
      Menu = Menu_IT;
      Content = Content_IT;
      Comments = Comments_IT;
      Footer = Footer_IT;
      AboutUs = AboutUs_IT;
      break;
    case 'de':
      Menu = Menu_De;
      Content = Content_DE;
      Comments = Comments_DE;
      Footer = Footer_DE;
      AboutUs = AboutUs_DE;
      break;
    default:
      Menu = Menu_IT;
      Content = Content_IT;
      Comments = Comments_IT;
      Footer = Footer_IT;
      AboutUs = AboutUs_IT;
  }

  return (
    <>
      <Menu />
      
      <Routes>
        <Route path="/blog/category/:cat" element={<Category />} />
        <Route path="/blog/home" element={<Home />} />
        <Route path="/blog" element={
          <>
            <Content />
            <Comments />
          </>
        } />
        <Route path="/blog/about" element={<AboutUs />} />
        <Route path="/blog/terms" element={<Terms />} />
        <Route path="/blog/privacy" element={<Privacy />} />
      </Routes>
      
      <Footer />
    </>
  );
};

export default Blog;
