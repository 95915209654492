import React, { useEffect,useState,useContext } from "react";
import { useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css'
import Context from '../../../_helpers/context';


export default function Category() {

    const [articoli, setArticoli] = useState("");
    const [def, setDef] = useState([]);
    let params = useParams();
	const category = params.cat;
    const context = useContext(Context)
    const lang = context.lang

   async function prendiFeed(){
         await fetch("")
         // "https://api.trkmyclick.com/get/news/"+category
        fetch("https://api.trkmyclick.com/get/news/"+lang+"/"+category, {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()) 
        .then(data => {
            setArticoli(data)
           
        })
 
   }

    useEffect(()  => {
       
        prendiFeed()
       
    },[]);

    useEffect(()  => {
       
        prendiFeed()
       
    },[category]);


    useEffect(()  => {
       if(articoli.articles){
        var tmp = []
        for(let i=0;i<35;i++){
           
            tmp.push(componiElementoMasonry(articoli.articles[i]))
        }
        setDef(tmp)
       }

        
       
    },[articoli]);
    
 
 

    


    function componiElementoMasonry(el){

        
        if(el){
         
                return (<>
                    {el.media && (
                        <article class="brick entry format-standard">

                        <div class="entry__thumb">
                            <a href="single-standard.html" class="thumb-link">
                                <img src={el.media}  alt=""/>
                            </a>
                        </div> 

                        <div class="entry__text">
                            <div class="entry__header">

                                <div class="entry__meta">
                                    <span class="entry__cat-links">
                                    
                                    </span>
                                </div>

                                <h1 class="entry__title"><a href={el.link}>{el.title}</a></h1>
                                
                            </div>
                            <div class="entry__excerpt">
                            
                                {el.summary.slice(0,200)} ...
                                
                            </div>
                        </div> 

                    </article> 
                    )}
                </> )
        }else{
            return (<></>)
        }
        
    }
 

    return (<div>
         <section class="s-pageheader">
            <div class="row current-cat">
                <div class="column">
                    <h1 class="h2">Category: {category}</h1>
                </div>
            </div>
        </section>


        <section class="s-bricks with-top-sep">

        <Masonry
            breakpointCols={3}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            
        
        </Masonry>

        <div class="masonry">

        
            <div class="bricks-wrapper h-group">

                <div class="grid-sizer"></div>

                {def.length > 0 && (
                    def
                )}

            

                </div> 
        
        </div>
        </section> 
            </div>)

}




