import React, { useEffect,useContext,useState } from "react";



import '../../css/styles.css'
import '../../css/vendor.css'

export default function About(match) {


    useEffect(()  => {
        
    },[]);



    return ( <section class="s-content">
    <div class="row">
        <div class="column large-12">

            <section>

                <div class="s-content__media">
                    <img src={require("../../images/about.jpg").default} alt="" />
                </div>

                <div class="s-content__primary">

                    <h1 class="s-content__title">Learn More About Us.</h1>	

                    <p class="lead">Dating Mail Magazine is a news collector truly greater than the sum of its parts. Driven by passion, guided by principles and acting with purpose, we are dedicated to delivering value to our customers and our shareholders with premium products and services that inform and inspire.</p>

                    <hr/>


                </div> 

            </section>

        </div> 
    </div>
</section>)

}