import React, { useEffect,useContext,useState } from "react";



import '../../css/styles.css'
import '../../css/vendor.css'

export default function About(match) {


    useEffect(()  => {
        
    },[]);



    return ( <section class="s-content">
    <div class="row">
        <div class="column large-12">

            <section>

                <div class="s-content__media">
                    <img src={require("../../images/about.jpg").default} alt="" />
                </div>

                <div class="s-content__primary">

                    <h1 class="s-content__title">Scopri di più su di noi</h1>	

                    <p class="lead">Dating Mail Magazine è un raccoglitore di notizie davvero più grande della somma delle sue parti. Spinti dalla passione, guidati dai principi e agendo con uno scopo, ci dedichiamo a fornire valore ai nostri clienti e ai nostri azionisti con prodotti e servizi premium che informano e ispirano.</p>

                    <hr/>


                </div> 

            </section>

        </div> 
    </div>
</section>)

}