import React, { useEffect,useContext,useState } from "react";



import '../css/styles.css'
import '../css/vendor.css'

export default function About(match) {


    useEffect(()  => {
        
    },[]);



    return ( <div class="row">
    <p>Política de privacidad para los Usuarios</p>
<p> ( versión 2.2021)</p>
<p> </p>
<p> </p>
<p> </p>
<p><strong>GANKO MEDIA SAGL</strong> con domicilio social en Via Cantonale, 4 – 6928 Manno,  CH  (de aquí en adelante, el " <strong>Titular</strong>"), propietario del sitio web  https://www.worldofwords.online(en adelante, el " Sitio"), como  <strong>Responsable del tratamiento  de datos personales</strong> de los usuarios que navegan y se registran en el Sitio (en adelante, los " <strong>Usuarios</strong>") proporciona a continuación la Política de privacidad de conformidad con el art. 13 del GDPR (General Data Protection Regulation) de la UE 2016/679 de 27 de abril de 2016 (en adelante, el " <strong>Reglamento</strong>" y/o los " <strong>Reglamentos aplicables</strong>").</p>
<p> </p>
<p>Este Sitio y todos sus servicios están reservados para mayores de dieciocho. Por lo tanto, el Titular no recopila datos personales relacionados con menores de 18 años. A petición de los Usuarios, el Titular eliminará de inmediato todos los datos personales recopilados inadvertidamente y relacionados con menores de 18 años.</p>
<p> </p>
<p>El Titular de esta web tiene en cuenta el derecho a la privacidad y la protección de datos personales de sus usuarios. Para cualquier información relacionada con esta Política de privacidad, los usuarios pueden  <strong>contactar con el Titular</strong> en cualquier momento, de distintas formas:</p>
<p> </p>
<ul>
<li>Enviando una carta certificada con acuse de recibo al domicilio social del Titular (GANKO MEDIA SAGL , Via Cantonale, 4 – 6928 Manno, CH);</li>
<li>Enviando un correo electrónico a: privacy@worldofwords.online.</li>
</ul>
<p> </p>
<p>El Titular no ha identificado la figura del administrador de protección de datos (DPO), ya que no está sujeto a la obligación de designación establecida en el art. 37 del Reglamento.</p>
<p> </p>
<p> </p>
<p> </p>
<ol>
<li><strong>Finalidad del tratamiento</strong></li>
</ol>
<p>Los datos personales de los usuarios serán procesados legalmente por el Titular de conformidad con el art. 6 del Reglamento para las siguientes finalidades:</p>
<p> </p>
<ol>
<li><strong>obligaciones contractuales y prestación del servicio</strong> , para permitir la navegación del Sitio o para ejecutar los Términos de uso del Sitio, que el Usuario acepta durante el registro en el Sitio para utilizar sus servicios, incluyendo el acceso a las plataformas de citas online y a los mensajes, así como para satisfacer las peticiones específicas del Usuario. Los datos del usuario que recopila el Titular para registrarse en el Sitio son: el nombre de usuario elegido de forma voluntaria, fecha de nacimiento, sexo, provincia de residencia/domicilio, el correo electrónico y toda la información personal que el Usuario haya publicado voluntariamente. A menos que el Usuario otorgue al Titular un consentimiento específico y opcional para el procesamiento de sus datos para las finalidades previstas en los siguientes párrafos, los datos personales del Usuario los utilizará el Titular, con el único objetivo de verificar la identidad del Usuario (también validando el correo electrónico), evitando así posibles estafas o abusos, y contactando al Usuario únicamente por razones de servicio (por ejemplo, enviando notificaciones relacionadas con los servicios que ofrece el Sitio). Sin perjuicio de lo dispuesto en otra parte de esta política de privacidad, en ningún caso el Titular hará que los datos personales de un Usuario sean accesibles para otro Usuario y / o terceros.</li>
<li><strong>fines de contabilidad y administración</strong> , o para llevar a cabo actividades de carácter organizativo, administrativo, financiero y contable, tales como actividades organizativas internas y actividades funcionales para el cumplimiento de obligaciones contractuales y precontractuales;</li>
<li><strong>obligaciones legales</strong> , o para cumplir obligaciones legales, una autoridad, un reglamento o la legislación europea.</li>
<li></li>
</ol>
<p>El suministro de datos personales para los fines de procesamiento indicados anteriormente es opcional pero necesario, ya que si no se proporcionan, será imposible que el Usuario navegue por el Sitio, se registre en él y utilice los servicios que ofrece el Titular del Sitio.</p>
<p> </p>
<p>Los datos personales necesarios para la consecución de los objetivos del tratamiento descritos en el párrafo 1, aparecen indicados con un asterisco en el formulario de registro del Sitio.</p>
<p> </p>
<p> </p>
<p> </p>
<ol start="2">
<li><strong>Objetivos del tratamiento: marketing y boletines informativos</strong></li>
</ol>
<p>Con el  <strong>consentimiento libre y facultativo</strong>  del Usuario, su nombre y apellidos, así como su correo electrónico, pueden ser tratados por el Titular con fines de marketing (como por ejemplo, el envío de material publicitario, ventas directas, comunicaciones comerciales, envío de boletines informativos sobre noticias relevantes para el sector relacionadas con las actividades del Titular y/o servicios y promociones relacionados con el contenido del Sitio web y los servicios que ofrece el Titular en el mismo). También para que el titular pueda contactar con el usuario por correo electrónico mandando ofertas, promociones y oportunidades comerciales de sus productos o de empresas de terceros.</p>
<p> </p>
<p>En el caso de que no haya consentimiento, el usuario podrá registrarse en el Sitio y disfrutar de sus servicios de igual forma.</p>
<p> </p>
<p>Si el usuario da su consentimiento, podrá retirarlo en cualquier momento solicitándolo al Titular de la manera indicada en el párrafo 5.</p>
<p> </p>
<p>El Usuario también podrá oponerse al envío de comunicaciones promocionales si retira su consentimiento haciendo clic en el enlace correspondiente. Dicho enlace está presente en todos los correos electrónicos promocionales. Una vez que el consentimiento ha sido retirado, el Titular enviará un mensaje de correo electrónico al Usuario para confirmar que ha retirado su consentimiento. Si el Usuario desea retirar su consentimiento de recibir comunicaciones promocionales por teléfono, mientras continúa recibiendo comunicaciones promocionales por correo electrónico, o viceversa, deberá solicitarlo al Titular de la manera indicada a continuación en el párrafo 5.</p>
<p> </p>
<p>El Titular informa que, a raíz del ejercicio del derecho a oponerse a recibir comunicaciones promocionales por correo electrónico, es posible que, por razones técnicas y operativas (por ejemplo, la formación de listas de contactos se haya completado poco antes de que el Titular reciba la solicitud de retirada del consentimiento) el Usuario continúe recibiendo algunos mensajes promocionales adicionales. Si el Usuario continúa recibiendo mensajes promocionales después de que hayan transcurrido 24 horas desde el ejercicio del derecho de oposición, deberá informar al Titular, utilizando los contactos indicados a continuación en el párrafo 5.</p>
<p> </p>
<p> </p>
<p> </p>
<ol start="3">
<li><strong>Modalidades de tratamiento y tiempos de retención de datos</strong></li>
</ol>
<p>El Titular tratará los datos personales de los Usuarios utilizando herramientas manuales e informáticas, con lógicas estrechamente relacionadas a los mismos fines y que garnticen la seguridad y confidencialidad de los datos.</p>
<p> </p>
<p>Los datos personales de los Usuarios de los Sitios se conservarán durante el tiempo estrictamente necesario hasta cumplir los objetivos indicados en el párrafo 1 o, según la necesidad, para la protección civil de los intereses tanto de los Usuarios como del Titular.</p>
<p> </p>
<p>En los casos mencionados en el párrafo 2, los datos personales de los Usuarios se conservarán durante el tiempo necesario para llevar a cabo los objetivos descritos en el mismo y, en todo caso, siempre que la ley permita su almacenamiento posterior para tales fines, incluida la defensa frente a reclamaciones judiciales.</p>
<p> </p>
<p>Si el Usuario decide bloquear y/o eliminar su perfil, se perderán todos sus datos almacenados. Si la ley no permite o exige la eliminación completa de los datos del Usuario, los datos se bloquean para su posterior tratamiento.</p>
<p> </p>
<p> </p>
<p> </p>
<ol start="4">
<li><strong>Comunicación y difusión de datos</strong></li>
</ol>
<p>Los empleados y o colaboradores del Titular responsables de administrar los Sitios pueden conocer los datos personales de los Usuarios. Estos sujetos, nombrados por el Titular como "<strong>Encargados del tratamiento</strong>", procesarán los datos del Usuario exclusivamente para los fines indicados en la presente política de privacidad y en cumplimiento de la ley aplicable.</p>
<p> </p>
<p>Además, los terceros también pueden conocer datos personales de los Usuarios en nombre del Titular como " <strong>Responsables externos de tratamiento</strong>", como por ejemplo, proveedores de servicios informáticos y logísticos funcionales para el funcionamiento del sitio, proveedores de  outsourcing o  cloud computing, profesionales y consultores.</p>
<p> </p>
<p>Los Usuarios tienen derecho a obtener una lista de los responsables del tratamiento de datos nombrados por el Titular, mediante una solicitud al Titular de la manera indicada en el párrafo 5.</p>
<p> </p>
<p> </p>
<p> </p>
<ol start="5">
<li><strong>Derechos de las partes interesadas</strong></li>
</ol>
<p>Los Usuarios pueden ejercer los derechos que les garantiza la ley aplicable, contactando con el Titular de diversas formas:</p>
<p> </p>
<ul>
<li>Enviando una carta certificada con acuse de recibo al domicilio social del Titular (GANKO MEDIA SAGL , Via Cantonale, 4 – 6928 Manno, CH);</li>
<li>Enviando un correo electrónico a: privacy@worldofwords.online.</li>
</ul>
<p>De conformidad con la ley aplicable, el Titular informa que los Usuarios tienen derecho a obtener la información sobre (i) el origen de los datos personales; (ii) la finalidad y modalidad del tratamiento; (iii) la lógica aplicada en el caso del tratamiento realizado con la ayuda de instrumentos electrónicos; (iv) datos de identificación del titular y de las personas responsables; (v) los sujetos o categorías de sujetos a los que se pueden comunicar los datos personales o que pueden conocerlos como gerentes o agentes.</p>
<p> </p>
<p>Además, los Usuarios tienen derecho a obtener:</p>
<p> </p>
<ol>
<li>a) acceso, actualización ,rectificación o, cuando estén interesados, integración de los datos;</li>
</ol>
<p> </p>
<ol>
<li>b) la eliminación, la transformación en forma anónimao el  bloqueode datos tratados que incumplen la ley, incluidos los datos que no son necesarios conservar para los fines para los que se recopilaron o procesaron;</li>
<li>c) la certificación de que las operaciones que corresponden con las letras a) y b) han sido notificadas, así como su contenido, a las entidades a las que se han comunicado o difundido los datos, a menos que este requisito resulte imposible o implique un esfuerzo claramente desproporcionado en comparación con el derecho que debe protegerse.</li>
</ol>
<p> </p>
<p>Además, los Usuarios tienen:</p>
<p> </p>
<ol>
<li>a) derecho a retirar el consentimientoen cualquier momento, si se trata de la base del tratamiento;</li>
<li>b) (cuando corresponda) el derecho a la portabilidad de datos(el derecho a recibir todos los datos personales que les conciernen en un formato estructurado, comúnmente utilizado y legible por un dispositivo automático), el derecho a limitarel tratamiento de datos personales y el  derecho de supresión ("derecho al olvido");</li>
<li>c) el derecho a oponerse:</li>
<li>d) en su totalidad o en parte, por razones legítimas, al tratamiento de datos personales que les conciernen, incluso si es pertinente para el propósito de la recopilación;</li>
<li>e) en todo o en parte, al procesamiento de datos personales que les conciernen con el fin de enviar material publicitario o ventas directas o para llevar a cabo estudios de mercado o de comunicación comercial;</li>
<li>f) si los datos personales se procesan con fines de marketing directo, en cualquier momento, para el procesamiento de sus datos con este fin, incluida la elaboración de perfiles en la medida en que estén conectados a dicho marketing directo.</li>
</ol>
<p> </p>
<p>El Titular no es responsable de actualizar todos los enlaces que se muestran en esta Política de cookies, por lo tanto, cada vez que un enlace no funcione o no se actualice, los usuarios reconocen y aceptan que siempre deben consultar el documento o la sección de los sitios web a las que se refiere dicho enlace.</p>
    </div>)

}