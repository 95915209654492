import React, { useEffect,useContext,useState } from "react";
import Context from '../_helpers/context';
const lang = require('./lang/lang.json')

export default function Eta(props) {

  const context = useContext(Context)
  const locale = context.lang

  function handleInputChange(target) {
    if(validateEmail(target.currentTarget.value)){
        var value = target.currentTarget.value;
        context.user_form.email = value
    }else{
        context.user_form.email = null
    }
    
  }


  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };



  return (
    <div >
        <input type="email" placeholder="Email" onChange={(e) => handleInputChange(e)} />
        { props.errore && (<div className="errore">{lang[locale].errore_email}</div>)}
    </div>
  );
}