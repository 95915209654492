import React, { useEffect,useContext,useState } from "react";



import '../../css/styles.css'
import '../../css/vendor.css'

export default function About(match) {


    useEffect(()  => {
        
    },[]);



    return ( <section class="s-content">
    <div class="row">
        <div class="column large-12">

            <section>

                <div class="s-content__media">
                    <img src={require("../../images/about.jpg").default} alt="" />
                </div>

                <div class="s-content__primary">

                    <h1 class="s-content__title">Erfahren Sie mehr über uns</h1>	

                    <p class="lead">Das Dating Mail Magazine ist wirklich ein Nachrichtensammler, der mehr ist als die Summe seiner Teile. Angetrieben von Leidenschaft, geleitet von Prinzipien und zielstrebigem Handeln sind wir bestrebt, unseren Kunden und Aktionären mit erstklassigen Produkten und Dienstleistungen, die informieren und inspirieren, einen Mehrwert zu bieten.</p>

                    <hr/>


                </div> 

            </section>

        </div> 
    </div>
</section>)

}