import React, { useEffect,useContext,useState } from "react";
import Context from '../../../_helpers/context';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css'

import '../css/styles.css'
import '../css/vendor.css'

export default function Blog() {
  
  const context = useContext(Context)
  const [articoli, setArticoli] = useState("");
  const [def, setDef] = useState([]);

  const category ="home";

  const lang = context.lang

  async function prendiFeed(){
    await fetch("")
    // "https://api.trkmyclick.com/get/news/"+category
   fetch("https://api.trkmyclick.com/get/news/"+lang+"/"+category, {
       method: 'GET',
       headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
       }
   })
   .then(response => response.json()) 
   .then(data => {
       setArticoli(data)
      
   })

}

useEffect(()  => {
  
   prendiFeed()
  
},[]);

useEffect(()  => {
    if(articoli.articles){
     var tmp = []
     for(let i=0;i<35;i++){
        
         tmp.push(componiElementoMasonry(articoli.articles[i]))
     }
     setDef(tmp)
    }

     
    
 },[articoli]);


 function componiElementoMasonry(el){

        
    if(el){
     
            return (<>
                {el.media && (
                    <article class="brick entry format-standard">

                    <div class="entry__thumb">
                        <a href="single-standard.html" class="thumb-link">
                            <img src={el.media}  alt=""/>
                        </a>
                    </div> 

                    <div class="entry__text">
                        <div class="entry__header">

                            <div class="entry__meta">
                                <span class="entry__cat-links">
                                
                                </span>
                            </div>

                            <h1 class="entry__title"><a href={el.link}>{el.title}</a></h1>
                            
                        </div>
                        <div class="entry__excerpt">
                        
                            {el.summary.slice(0,200)} ...
                            
                        </div>
                    </div> 

                </article> 
                )}
            </> )
    }else{
        return (<></>)
    }
    
}


    return (<div>
          <div class="brick entry featured-grid">
                    <div class="entry__content">

                        <div class="featured-post-slider">
                            
                            <div class="featured-post-slide">
                                <div class="f-slide">
                                    
                                    <div class="f-slide__background" style={{ backgroundImage: `url(${require("../images/5.jpg").default})` }}></div>
                                    <div class="f-slide__overlay"></div>

                                    <div class="f-slide__content">
                                        <ul class="f-slide__meta">
                                            <li>Ottobre 05, 2022</li> 
                                            
                                        </ul>	

                                        <h1 class="f-slide__title"><Link to="/blog" title="">Siti di incontri. Quale scegliere per non rimanere delusi?</Link></h1> 
                                    </div>

                                </div> 
                            </div>


                        </div>
                        
                        <div class="featured-post-nav">
                            <button class="featured-post-nav__prev">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
                            </button>
                            <button class="featured-post-nav__next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
                            </button>
                        </div>

                    </div>
                </div> 

                <section class="s-bricks with-top-sep">

        <Masonry
            breakpointCols={5}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            
        
        </Masonry>

        <div class="masonry">

        
            <div class="bricks-wrapper h-group">

                <div class="grid-sizer"></div>

                {def.length > 0 && (
                    def
                )}

            

                </div> 
        
        </div>
        </section> 
     
    </div>)

}