import React, { useEffect,useContext,useState } from "react";




export default function Blog(user) {


    useEffect(()  => {
        
    },[]);

 

    return (<div>
     <div className="comments-wrap">

<div id="comments" className="row">
    <div className="column">

        <h3>3 Kommentare</h3>


        <ol className="commentlist">

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-08.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Jenell</div>

                        <div className="comment__meta">
                            <div className="comment__time">05. JANUAR 2022</div>
                            <div className="comment__reply">
                              
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Offensichtlich sind die meisten Frauen auf dieser Seite bereits nach wenigen Nachrichten bereit für ein Date. Die Männer, die mich nach ein paar Nachrichten kontaktierten, baten mich sofort um ein Treffen und sagten, so sei das auch bei anderen.</p>
                    </div>

                </div>

            </li> 

            <li className="thread-alt depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-01.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Johann</div>

                        <div className="comment__meta">
                            <div className="comment__time">05. MÄRZ 2022</div>
                            <div className="comment__reply">
                                
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Ich bin jetzt seit einem Jahr Mitglied und es macht mir immer noch Spaß. =P</p>
                    </div>

                </div>


            </li> 

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-04.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Volker</div>

                        <div className="comment__meta">
                            <div className="comment__time">03. SEPTEMBER 2022</div>
                            <div className="comment__reply">
                               
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Es ist nützlich für diejenigen, die nicht wissen, wie man Frauen persönlich anspricht</p>
                    </div>

                </div>

            </li> 

        </ol>
       

    </div> 
</div> 




</div> 
    </div>)

}




