import React, { useEffect,useContext,useState } from "react";
import Context from '../_helpers/context';
const lang = require('./lang/lang.json')

export default function Eta(props) {

  const context = useContext(Context)
  const locale = context.lang

  function handleInputChange(target) {
        if(target.currentTarget.value != ""){
            var value = target.currentTarget.value;
            context.user_form.password = value
        }else{
            context.user_form.password = null
        }
        
 }

  return (
    <div >
        <input type="password" placeholder="Password" onChange={(e) => handleInputChange(e)} />
        { props.errore && (<div className="errore">{lang[locale].errore_psw}</div>)}
    </div>
  );
}