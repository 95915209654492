import React, { useEffect,useContext,useState } from "react";
import { Link } from 'react-router-dom';



export default function Blog(user) {


    useEffect(()  => {
        
    },[]);

 

    return (<div>
      <footer class="s-footer">

<div class="s-footer__main">

    <div class="row">

        <div class="column large-4 medium-6 tab-12 s-footer__info">

            <h5>Sobre nosotros</h5>

            <p>
            Busquemos en la red cuáles son los mejores servicios. Ofrecemos noticias de actualidad y queremos que nuestros artículos sean funcionales y entretenidos de leer.
            </p>

        </div> 

        <div class="column large-2 medium-3 tab-6 s-footer__site-links">

            <h5>Links</h5>

            <ul>
                <li><Link to="/blog/about" title="">Sobre nosotros</Link></li>
                <li><Link to="/blog" title="">Inicio</Link></li>
                <li><Link to="/blog/terms">Términos y condiciones</Link></li>
                <li><Link to="/blog/privacy">Política de privacidad</Link></li>
            </ul>

        </div> 


        

    </div>

</div> 

<div class="s-footer__bottom">
    <div class="row">
        <div class="column">
            <div class="ss-copyright">
                <span>© Copyright 2020</span> 
                <span>Design by DatingMail Magazine</span>		         	
            </div> 
        </div>
    </div> 

    <div class="ss-go-top">
        <a class="smoothscroll" title="Back to Top" href="#top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 4h12v2H6zm5 10v6h2v-6h5l-6-6-6 6z"/></svg>
        </a>
    </div>
</div>

</footer>
    </div>)

}




