import React, { useEffect,useContext,useState,useRef } from "react";
import Context from '../_helpers/context';
const lang = require('./lang/lang.json')

export default function Password_Email(props) {

  const context = useContext(Context)
  const locale = context.lang

 

  const [selectedVal, setSelectedVal] = useState(context.user_form.email ? context.user_form.email : "");
  const [selectedValPsw, setSelectedValPsw] = useState(context.user_form.password ? context.user_form.password : "");

 

  function handleInputChangeEmail(target) {
    if(validateEmail(target.currentTarget.value)){
        var value = target.currentTarget.value;
        context.user_form.email = value
    }else{
        context.user_form.email = null
    }

    setSelectedVal(target.currentTarget.value)

  }


  function handleInputChangePassword(target) {
    if(target.currentTarget.value != ""){
        var value = target.currentTarget.value;
        context.user_form.password = value
    }else{
        context.user_form.password = null
    }
    setSelectedValPsw(target.currentTarget.value)
}


  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => { 

  },[]);



  return (
    <div >
        <input type="email" placeholder="Scegli una Email" value={selectedVal} onChange={(e) => handleInputChangeEmail(e)} />
        { props.email && (<div className="errore">{lang[locale].errore_email}</div>)}
        <input type="password" placeholder="Scegli una Password" value={selectedValPsw} onChange={(e) => handleInputChangePassword(e)} />
        { props.password && (<div className="errore">{lang[locale].errore_psw}</div>)}
    </div>
  );
}