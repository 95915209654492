import React, { useEffect,useContext,useState } from "react";




export default function Blog(user) {


    useEffect(()  => {
        
    },[]);

 

    return (<div>
     <div className="comments-wrap">

<div id="comments" className="row">
    <div className="column">

        <h3>3 Comments</h3>


        <ol className="commentlist">

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-08.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Rocio Nuÿ</div>

                        <div className="comment__meta">
                            <div className="comment__time">Jan 05, 2022</div>
                            <div className="comment__reply">
                              
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Fácil de usar. Sólo que evidentemente la mayoría de las mujeres están dispuestas a irse después de unos pocos mensajes. Los hombres que se pusieron en contacto conmigo después de unos cuantos mensajes me pidieron inmediatamente que quedáramos, diciendo que así funcionaba con los demás.</p>
                    </div>

                </div>

            </li> 

            <li className="thread-alt depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-01.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Mario Rui</div>

                        <div className="comment__meta">
                            <div className="comment__time">Mar 05, 2022</div>
                            <div className="comment__reply">
                                
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Soy miembro desde hace un año y todavía me divierto.. :)</p>
                    </div>

                </div>


            </li> 

            <li className="depth-1 comment">

                <div className="comment__avatar">
                    <img className="avatar" src={require("../../images/user-04.jpg").default} alt="" width="50" height="50"/>
                </div>

                <div className="comment__content">

                    <div className="comment__info">
                        <div className="comment__author">Lorenzo Sevilla</div>

                        <div className="comment__meta">
                            <div className="comment__time">Sept 03, 2022</div>
                            <div className="comment__reply">
                               
                            </div>
                        </div>
                    </div>

                    <div className="comment__text">
                    <p>Me parece muy útil para aquellos que no pueden acercarse a las mujeres en persona</p>
                    </div>

                </div>

            </li> 

        </ol>
       

    </div> 
</div> 




</div> 
    </div>)

}




