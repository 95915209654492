import React, { useEffect,useContext,useState } from "react";
import { Link } from 'react-router-dom';
import Context from '../../../../_helpers/context';


export default function Blog(user) {

    const context = useContext(Context)

    const [terms, setTerms] = useState("");

    useEffect(()  => {
        setTerms(context.terms)
    },[]);

 

    return (<div>
      <footer class="s-footer">

<div class="s-footer__main">

    <div class="row">

        <div class="column large-4 medium-6 tab-12 s-footer__info">

            <h5>Über uns..</h5>

            <p>
            Wir durchsuchen das Internet nach den besten Dienstleistungen. Wir bieten aktuelle Nachrichten und möchten, dass unsere Artikel funktional und unterhaltsam sind.
            </p>

        </div> 

        <div class="column large-2 medium-3 tab-6 s-footer__site-links">

            <h5>Links</h5>

            <ul>
                <li><Link to="/blog/about" title="">Über uns</Link></li>
                <li><Link to="/blog" title="">Heim</Link></li>
                <li><Link to="/blog/terms">Geschäftsbedingungen</Link></li>
                <li><Link to="/blog/privacy">Datenschutz-Bestimmungen</Link></li>
            </ul>

        </div> 


        {terms && (<div className="dynterms" dangerouslySetInnerHTML={{__html: terms}}></div>)}


        

    </div>

</div> 

<div class="s-footer__bottom">
    <div class="row">
        <div class="column">
            <div class="ss-copyright">
                <span>© Copyright 2020</span> 
                <span>Design by DatingMail Magazine</span>		         	
            </div> 
        </div>
    </div> 

    <div class="ss-go-top">
        <a class="smoothscroll" title="Back to Top" href="#top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 4h12v2H6zm5 10v6h2v-6h5l-6-6-6 6z"/></svg>
        </a>
    </div>
</div>

</footer>
    </div>)

}




